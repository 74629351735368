import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { CartIcon, CloseIcon, MenuIcon, POST_RFQ } from "Assets/icons";
import AuthBar from "Components/infos/AuthBar";
import LogoFreshdi from "Components/LogoFreshdi";
import Row from "Components/Row";
import useAuth from "hooks/useAuth";
import { useTranslation } from "hooks/useTranslation";
import { DIRECT_PATHS, PATHS } from "constants/routes";
import { ALink, Link } from "Components/link";
import { SmTextMd, TextSemibold, XsText } from "Components/Text";
import { Img } from "Components/Img";
import { TextButton } from "Components/Button";
import { RegisterButton } from "Components/button";
import { COLORS } from "constants/layout";
import { useCommonData } from "providers/common-data";
import { useMemo, useState } from "react";
import HeaderServices from "./HeaderServices";

const MobileMenuHeader = ({
  onClose,
  isWorkSpace,
}: {
  onClose?: () => void;
  isWorkSpace?: boolean;
}) => {
  const t = useTranslation();
  const { isLoggedIn } = useAuth();
  const { appConfigs } = useCommonData();
  const [expandedCategories, setExpandedCategories] = useState(false);

  const categorries = useMemo(
    () =>
      expandedCategories
        ? appConfigs?.allCates || []
        : (appConfigs?.allCates || []).slice(0, 6),
    [appConfigs?.allCates, expandedCategories]
  );

  const onToggleCategories = () => setExpandedCategories(!expandedCategories);

  return (
    <StyledMobileHeader>
      <Row justifyContent="space-between" p={2} pb={1} className="header">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Row spacing={1}>
          <LogoFreshdi />
          {isWorkSpace && (
            <TextSemibold
              fontSize={10}
              fontStyle="italic"
              color={COLORS.green[600]}
            >
              {t("buyer_workspace")}
            </TextSemibold>
          )}
        </Row>
        <AuthBar hideUserName />
      </Row>

      <Stack spacing={1.5} className="mobileheader">
        <Divider />
        <Stack spacing={1.5} p={2}>
          <Stack spacing={0.5}>
            <SmTextMd
              color={COLORS.gray[500]}
              display="flex"
              gap={1}
              onClick={onToggleCategories}
              sx={{ cursor: "pointer" }}
            >
              <MenuIcon />
              {t("AllCategories")}
            </SmTextMd>
            <Collapse in={expandedCategories}>
              <Box pl={3.25}>
                {categorries.map((category, index) => (
                  <Link
                    key={index}
                    to={DIRECT_PATHS.categorySupplier(category.value)}
                    onClick={onClose}
                  >
                    <XsText className="category-text">{category.label}</XsText>
                  </Link>
                ))}
              </Box>
            </Collapse>
          </Stack>

          <Link to={PATHS.postRfq} onClick={onClose}>
            <TextButton
              className="quickrfq-btn"
              startIcon={<Img src={POST_RFQ} alt="post rfq" />}
            >
              {t("get_quote_from_supplier")}
            </TextButton>
          </Link>
          <ALink href={PATHS.buyleads}>
            <TextButton startIcon={<CartIcon />} className="leads-btn">
              {t("buying_leads")}
            </TextButton>
          </ALink>
          <HeaderServices />
        </Stack>

        <Divider />

        <Stack spacing={2} px={2}>
          <Box mx={isLoggedIn ? "0" : "auto"}>
            <AuthBar />
          </Box>
          {!isLoggedIn && <RegisterButton />}
        </Stack>
      </Stack>
    </StyledMobileHeader>
  );
};

export const StyledMobileHeader = styled("div")({
  ".header": {
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1,
  },
  ".mobileheader .service_link": {
    flexDirection: "row-reverse",
    gap: 12,
    svg: {
      // display: "none",
    },
    ".label": {
      marginTop: 0,
      fontSize: 16,
      fontWeight: 600,
      color: "#667085",
    },
  },
  ".category-text": {
    padding: "4px 0",
  },
  ".quickrfq-btn, .leads-btn": {
    fontSize: 14,
    fontWeight: 500,
  },
  ".quickrfq-btn": {
    color: COLORS.yellow[500],
  },
  ".leads-btn": {
    color: COLORS.green[600],
  },
  ".logo": {
    height: 28,
  },
});

export default MobileMenuHeader;
