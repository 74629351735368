import { FILE_DOMAIN, RESIZE_IMG_DOMAIN } from "constants/schemas";
import DEFAULT_IMAGE from "../Assets/images/default_img.webp";

const checkListProtocols = (link = "") => {
  let protocols = ["http", "https"],
    checkListProtocols = false;
  protocols?.map((prototal) => {
    if (link?.indexOf(prototal) > -1) {
      checkListProtocols = true;
    }
    return prototal;
  });
  return checkListProtocols;
};

const getMatchImageSize = (type = 0) => {
  switch (type) {
    case 0:
      return "64x64";
    case 1:
      return "128x128";
    case 2:
      return "240x240";
    case 3:
      return "350x350";
    case 4:
      return "480x480";
    case 5:
      return "640x640";
    case 6:
      return "720x720";
    case 7:
      return "1024x1024";
    default:
      return "240x240";
  }
};

const getThumbnail = (url, type) => {
  let thumbnailImg = null;
  if (url) {
    thumbnailImg = url.replace("m3u8", "jpeg");
  }
  return `${RESIZE_IMG_DOMAIN}/${getMatchImageSize(type)}${thumbnailImg}`;
};

const checkIsVideo = (suffix) => {
  let isVideo = false;
  if (suffix) {
    isVideo = suffix?.indexOf(".m3u8") > -1;
  }
  return isVideo;
};

const formatImgUrl = (suffix, type = -1, isThumbnail = false) => {
  return suffix
    ? checkListProtocols(suffix)
      ? suffix
      : checkIsVideo(suffix)
      ? isThumbnail
        ? getThumbnail(suffix, type)
        : `${RESIZE_IMG_DOMAIN}${suffix}`
      : type > -1
      ? `${RESIZE_IMG_DOMAIN}/${getMatchImageSize(type)}${suffix}`
      : `${FILE_DOMAIN}${suffix}`
    : DEFAULT_IMAGE;
};

const formatImg = formatImgUrl;

const formatError = (err) => {
  let defaultError = {};
  try {
    const errorRes = (JSON.parse(err?.response?.data?._server_messages) ||
      "[{}]")[0];
    return JSON.parse(errorRes) || defaultError;
  } catch (error) {
    return defaultError;
  }
};

export { formatImgUrl, checkIsVideo, formatError, formatImg };
