import { Stack, styled } from "@mui/material";
import { Img } from "../Img";
import { TextSemibold, XsText } from "../Text";
import { DIRECT_PATHS } from "constants/routes";
import TimeDiff from "../TimeDiff";
import dayjs from "dayjs";
import CountryFlag from "../CountryFlag";
import { useIntl } from "react-intl";
import { formatImgUrl } from "utils/helpers";
import Row from "../Row";
import { PlanChip } from "../Plan";
import { VERIFIED2_ICON } from "Assets/icons";
import { ElementType } from "react";
import { ALink } from "../link";
import { COLORS } from "constants/layout";

export const BaseSupplierInfors = ({
  company,
  nameComponent = "h2",
}: {
  company: any;
  nameComponent?: ElementType;
}) => {
  const t = useIntl();
  const {
    company_country,
    country_code,
    last_active,
    name,
    farm_avatar,
    farm_name,
    plan_name,
    is_verified,
  } = company || {};
  const NOW = dayjs();

  return (
    <StyledBaseSupplierInfors
      direction="row"
      alignItems="flex-start"
      spacing={1}
    >
      {farm_avatar && (
        <Img
          src={formatImgUrl(farm_avatar, 0)}
          alt="img"
          className="company_img"
        />
      )}
      <Stack
        spacing={0.5}
        flex={1}
        maxWidth={`calc(100% - ${farm_avatar ? "48px" : "0px"})`}
      >
        {farm_name && (
          <ALink href={DIRECT_PATHS.companyDetail(name)}>
            <TextSemibold
              className="company_name ellipsis-text"
              component={nameComponent}
            >
              {farm_name}
            </TextSemibold>
          </ALink>
        )}

        <Row spacing={0.5} mb={0.5} minHeight={20}>
          <PlanChip plan={plan_name} />
          {is_verified === 1 && (
            <Img
              src={VERIFIED2_ICON}
              alt="icon"
              className="verified-icon"
              height={12}
            />
          )}
          <CountryFlag fd_country={company_country} {...{ country_code }} />
        </Row>

        {last_active && (
          <XsText className="active-text">
            {t.formatMessage(
              { id: "active_value_ago" },
              {
                value: (
                  <TimeDiff
                    endTime={NOW.format()}
                    startTime={dayjs(last_active).format()}
                  />
                ),
              }
            )}
          </XsText>
        )}
      </Stack>
    </StyledBaseSupplierInfors>
  );
};

const StyledBaseSupplierInfors = styled(Stack)({
  fontSize: 16,
  width: "100%",
  ".company_name": {
    color: "#1D2939",
    span: { color: COLORS.gray[600], fontSize: 12, fontWeight: 400 },
  },
  ".company_img": {
    width: 40,
    height: 40,
    objectFit: "contain",
  },
  ".flag": {
    width: 16,
    height: 16,
  },
  ".active-text": {
    color: "var(--gray-600)",
  },
});
