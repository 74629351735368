import { Dialog } from "@mui/material";
import Disclaimer from "Components/Disclaimer";
import { XsText } from "Components/Text";
import { useTranslation } from "hooks/useTranslation";
import React, { memo, useState } from "react";
import { FiInfo } from "react-icons/fi";

type Props = {};

const DisclaimerButton = (props: Props) => {
  const t = useTranslation();
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const onClickInfoIcon = () => {
    setShowDisclaimer(true);
  };

  const onCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  return (
    <>
      <XsText
        display="flex"
        alignItems="center"
        gap={1}
        onClick={onClickInfoIcon}
      >
        <FiInfo cursor="pointer" />
        {t("disclaimer")}
      </XsText>

      <Dialog
        open={showDisclaimer}
        onClose={onCloseDisclaimer}
        PaperProps={{
          sx: {
            maxWidth: 544,
            width: "100%",
          },
        }}
      >
        <Disclaimer onClose={onCloseDisclaimer} />
      </Dialog>
    </>
  );
};

export default memo(DisclaimerButton);
