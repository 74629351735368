import {
  TextFieldProps,
  TextField,
  styled,
  StackProps,
  Stack,
  Select as SelectLib,
  IconButton,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { forwardRef, useState } from "react";
import { SmText, XsText } from "./Text";
import { IoMdClose } from "react-icons/io";
import { OptionType } from "types";

type FakeInputType = {
  error?: boolean;
};

export type InputProp = TextFieldProps & {
  toplabel?: string;
  requiredlabel?: boolean;
};

export const Input = forwardRef<any, InputProp>(
  ({ toplabel, requiredlabel, ...props }, ref) => {
    return (
      <Stack spacing={1}>
        {toplabel && (
          <SmText
            className={"input-label" + (requiredlabel ? " required" : "")}
          >
            {toplabel}
          </SmText>
        )}
        <BaseInput fullWidth ref={ref} autoComplete="off" {...props} />
      </Stack>
    );
  }
);

export const PasswordInput = forwardRef<any, InputProp>(
  ({ toplabel, requiredlabel, ...props }, ref) => {
    const [showPw, setShowPw] = useState(false);

    const onClickShowPw = () => {
      setShowPw(!showPw);
    };

    return (
      <Input
        toplabel={toplabel}
        requiredlabel={requiredlabel}
        type={showPw ? "text" : "password"}
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <IconButton
              className="fit eye-icon"
              onClick={onClickShowPw}
              edge="end"
            >
              {showPw ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          ),
        }}
        ref={ref}
        {...props}
      />
    );
  }
);

export const BelowChips = ({
  customValue = [],
  options,
  onDeleteSelect,
}: {
  customValue?: any[];
  options?: OptionType[];
  onDeleteSelect?: (value: string | number) => void;
}) => {
  if (customValue?.length < 1) return null;
  return (
    <Stack direction="row" flexWrap="wrap" gap={0.5}>
      {(customValue || []).map((v: any) => {
        const selectedLabel =
          typeof v === "string"
            ? options?.find((o) => o.value === v)?.label
            : v?.label;

        return (
          <SelectChip key={v}>
            <XsText>{selectedLabel}</XsText>
            <IoMdClose onClick={() => onDeleteSelect?.(v)} />
          </SelectChip>
        );
      })}
    </Stack>
  );
};

export const RoundedInput = forwardRef((props: TextFieldProps, ref: any) => {
  return (
    <StyledRoundedInput
      ref={ref}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
});

export const FakeInput = (props: StackProps & FakeInputType) => {
  return <StyledFakeInput {...props} error={props.error} />;
};

export const BaseInput = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-root": {
    overflow: "hidden",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    padding: "10px 14px",
  },
  "& .MuiOutlinedInput-input": {
    padding: 0,
  },
  ".eye-icon": {
    svg: {
      fontSize: 20,
      marginRight: 8,
    },
  },
  button: {
    fontSize: 14,
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    input: {
      fontSize: 12,
    },
    "& .MuiInputBase-root": {
      padding: "10px 14px",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
      fontSize: 12,
    },
    button: {
      fontSize: 12,
    },
  },
}));

export const BaseSelect = styled(SelectLib)(({ theme }) => ({
  minHeight: 37,
  ".MuiSelect-select": {
    padding: "9px 14px",
    backgroundColor: "#fff",
    "&,& p": {
      fontSize: 14,
    },
  },
  ".MuiInputBase-root": {},
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    ".MuiSelect-select": {
      padding: "7px 14px",
      "&,& p": {
        fontSize: 12,
      },
    },
  },
}));

const StyledRoundedInput = styled(TextField)({
  // input: {
  //   padding: "10px 14px",
  // },
});

const SelectChip = styled("div")({
  borderRadius: 8,
  padding: 8,
  border: "1px solid #E0E0E0",
  display: "flex",
  gap: 8,
  alignItems: "center",
  svg: {
    cursor: "pointer",
  },
});

const StyledFakeInput = styled(Stack)<{ error?: boolean }>(({ error }) => ({
  padding: "10px 14px",
  border: `1px solid ${error ? "#f03e3e !important" : "#D0D5DD"}`,
  borderRadius: 8,
  ":hover": {
    borderColor: "#000",
  },
  ".hidden": {
    display: "none",
  },
  cursor: "pointer",
}));
