export * from "./user.icon";
export * from "./target.icon";
export * from "./home.icon";
export * from "./send.icon";
export * from "./inquiry.icon";
export * from "./check.icon";
export * from "./regenerate.icon";
export * from "./delete.icon";
export * from "./crown.icon";
export * from "./bag.icon";
export * from "./info.icon";
export * from "./bell.icon";
export * from "./chat.icon";
export * from "./setting.icon";
export * from "./arrow.icon";
export * from "./info.icon";
export * from "./close.icon";
export * from "./note.icon";
export * from "./package.icon";
export * from "./flag.icon";
export * from "./email.icon";
export * from "./filter.icon";
export * from "./upload.icon";
export * from "./dashboard.icon";
export * from "./arrow.icon";
export * from "./like.icon";
export * from "./shield.icon";
export * from "./reload.icon";
export * from "./gift.icon";
export * from "./thunder.icon";
export * from "./sale.icon";
export * from "./phone.icon";
export * from "./goal.icon";
export * from "./idea.icon";
export * from "./successful.icon";
export * from "./cart.icon";
export * from "./logout.icon";
export * from "./ship.icon";
export * from "./lock.icon";
export * from "./search.icon";
export * from "./menu.icon";
export * from "./login.icon";
export * from "./edit.icon";
export * from "./question.icon";

export const VERIFY_ICON = require("./verified.png").default;
export const GOOGLE_ICON = require("./google.svg").default;
export const VERIFIED2_ICON = require("./verified_2.svg").default;
export const COFFEE_ICON = require("./category/coffee.svg").default;
export const VEGETABLES_ICON = require("./category/vegetables.svg").default;
export const RICE_ICON = require("./category/rice.svg").default;
export const TEA_ICON = require("./category/tea.svg").default;
export const NUT_ICON = require("./category/nut.svg").default;
export const FRUIT_ICON = require("./category/fruit.svg").default;
export const SEAFOOD_ICON = require("./category/aqua.svg").default;
export const TIMBER_ICON = require("./category/timber.svg").default;
export const MEAT_ICON = require("./category/meat.svg").default;
export const RICE_2_ICON = require("./category/rice_2.svg").default;
export const COFFEE_2_ICON = require("./category/coffee_2.svg").default;
export const FRUIT_2_ICON = require("./category/fruit_2.svg").default;
export const TEA_2_ICON = require("./category/tea_2.svg").default;
export const FRESH_VEGETABLES_ICON =
  require("./category/fresh_vegetables.svg").default;
export const FISH_ICON = require("./category/fish.svg").default;
export const GOLD_RANK_ICON = require("./rank/gold.svg").default;
export const DIAMOND_RANK_ICON = require("./rank/diamond.svg").default;
export const FREE_RANK_ICON = require("./rank/free.svg").default;
export const BUYER_BENEFIT_1 = require("./rank/buyer_benefit_1.svg").default;
export const BUYER_BENEFIT_2 = require("./rank/buyer_benefit_2.svg").default;
export const BUYER_BENEFIT_3 = require("./rank/buyer_benefit_3.svg").default;
export const GREEN_SHIP_ICON = require("./ship.svg").default;
export const GREEN_SHIELD_ICON = require("./shield.svg").default;
export const POST_RFQ = require("./postRfq.svg").default;
export const YELLOW_CROWN = require("./yellow_crown.svg").default;
export const SUCESSFUL_ICON = require("./success.svg").default;
export const FAIL_ICON = require("./fail.svg").default;
export const SENT_MAIL_ICON = require("./sent_mail.svg").default;
export const SEND_MAIL_ICON = require("./send_email.svg").default;
export const RED_WARNING_ICON = require("./red_warning.svg").default;
export const VERIFIED_ICON = require("./verified.svg").default;
export const UNVERIFIED_ICON = require("./unverified2.svg").default;
export const THUNDER_ICON = require("./thunder.svg").default;
export const REJECT_ICON = require("./reject.svg").default;
export const CIRCLE_CROWN_ICON = require("./circle_crown.svg").default;
export const CIRCLE_VERIFIED_ICON = require("./circle_verified.svg").default;
export const CIRCLE_UNVERIFIED_ICON =
  require("./circle_unverified.svg").default;
export const STARTED_CROWN_ICON = require("./started_crown.svg").default;
export const ADVANCE_CROWN_ICON = require("./advance_crown.svg").default;
export const PRO_CROWN_ICON = require("./pro_crown.svg").default;

export const APP_ICONS = {
  planPremium: "/icons/plan_premium.png",
  planAdvance: "/icons/plan_advance.png",
  planSuperior: "/icons/plan_superior.png",
  paymentPaypal: "/icons/paypal.svg",
  paymentCreditCard: "/icons/credit_card.svg",
  paymentWireTransfer: "/icons/wire_transfer.svg",
  download: "/icons/download.svg",
  sparkless: "/icons/sparkles.svg",
  handBullet: "/icons/hand_bullet.svg",
  postRfq: "/icons/post_rfq.svg",
  edit: "/icons/edit.svg",
  chevronDown: "/icons/chevron_down.svg",
  thumbsup: "/icons/thumbsup.svg",
};
