import { SVGProps } from "react";

export function ReloadIcon({
  width = 51,
  height = 50,
  viewBox = "0 0 51 50",
  stroke = "#019C3C",
  strokeWidth = 3.3,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_11874_54145)">
        <path
          d="M48.2474 8.33335V20.8333M48.2474 20.8333H35.7474M48.2474 20.8333L38.5807 11.75C36.3417 9.50983 33.5716 7.87336 30.529 6.99329C27.4865 6.11323 24.2705 6.01825 21.1813 6.71722C18.0921 7.41619 15.2303 8.88634 12.863 10.9905C10.4956 13.0946 8.69983 15.7642 7.64323 18.75M2.41406 41.6667V29.1667M2.41406 29.1667H14.9141M2.41406 29.1667L12.0807 38.25C14.3198 40.4902 17.0898 42.1267 20.1324 43.0067C23.175 43.8868 26.3909 43.9818 29.4801 43.2828C32.5693 42.5838 35.4311 41.1137 37.7985 39.0096C40.1659 36.9054 41.9616 34.2359 43.0182 31.25"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11874_54145">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.330078)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SmReloadIcon({
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox={"0 0 18 18"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M0.75 2.99993V7.49993M0.75 7.49993H5.25M0.75 7.49993L4.23 4.22993C5.03606 3.42347 6.03328 2.83434 7.12861 2.51751C8.22393 2.20069 9.38167 2.1665 10.4938 2.41813C11.6059 2.66976 12.6361 3.19901 13.4884 3.9565C14.3407 4.71399 14.9871 5.67502 15.3675 6.74993M17.25 14.9999V10.4999M17.25 10.4999H12.75M17.25 10.4999L13.77 13.7699C12.9639 14.5764 11.9667 15.1655 10.8714 15.4824C9.77607 15.7992 8.61833 15.8334 7.50621 15.5817C6.3941 15.3301 5.36385 14.8009 4.5116 14.0434C3.65935 13.2859 3.01288 12.3248 2.6325 11.2499"
        stroke="#065986"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
