import { SVGProps } from "react";

export function LgLikeIcon({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  fill = "#F04438",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M16 7.14262C16 6.75685 15.8468 6.38688 15.574 6.1141C15.3012 5.84132 14.9312 5.68808 14.5455 5.68808H9.94909L10.6473 2.36444C10.6618 2.29171 10.6691 2.21171 10.6691 2.13171C10.6691 1.83353 10.5455 1.55717 10.3491 1.3608L9.57818 0.597168L4.79273 5.38262C4.52364 5.65171 4.36364 6.01535 4.36364 6.41535V13.6881C4.36364 14.0738 4.51688 14.4438 4.78966 14.7166C5.06244 14.9894 5.43241 15.1426 5.81818 15.1426H12.3636C12.9673 15.1426 13.4836 14.779 13.7018 14.2554L15.8982 9.12808C15.9636 8.9608 16 8.78626 16 8.59717V7.14262ZM0 15.1426H2.90909V6.41535H0V15.1426Z"
        fill={fill}
      />
    </svg>
  );
}

export function LikeIcon({
  fill = "#008934",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox={"0 0 15 16"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M14.375 6.75C14.375 6.41848 14.2433 6.10054 14.0089 5.86612C13.7745 5.6317 13.4565 5.5 13.125 5.5H9.175L9.775 2.64375C9.7875 2.58125 9.79375 2.5125 9.79375 2.44375C9.79375 2.1875 9.6875 1.95 9.51875 1.78125L8.85625 1.125L4.74375 5.2375C4.5125 5.46875 4.375 5.78125 4.375 6.125V12.375C4.375 12.7065 4.5067 13.0245 4.74112 13.2589C4.97554 13.4933 5.29348 13.625 5.625 13.625H11.25C11.7688 13.625 12.2125 13.3125 12.4 12.8625L14.2875 8.45625C14.3438 8.3125 14.375 8.1625 14.375 8V6.75ZM0.625 13.625H3.125V6.125H0.625V13.625Z"
        fill={fill}
      />
    </svg>
  );
}
