import {
  InputAdornment,
  Stack,
  StackProps,
  TextField,
  debounce,
  styled,
} from "@mui/material";

import { useCallback, useRef, useState } from "react";
import { DIRECT_PATHS, SEARCH_PARAMS } from "constants/routes";
import Row from "../Row";
import { useQueryParam } from "helpers/search";
import { COLORS } from "constants/layout";
import { GreenButton } from "../Button";
import { useTranslation } from "hooks/useTranslation";
import { SearchIcon } from "Assets/icons";
import { ALink, Link } from "Components/link";
import { StyledAutocomplete } from "Components/input/Autocomplete.styles";
import { OptionType } from "types";
import { apiSearchProductCategory } from "services/api/helpers";
import { useHistory } from "react-router-dom";

const HeaderSearch = (props: StackProps) => {
  const t = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const history = useHistory();
  const searchKeyWordParam = useQueryParam(SEARCH_PARAMS.keyword);
  const searchSKeyWordParam = useQueryParam(SEARCH_PARAMS.sKeyword);
  const [selectedCategory, setSelectedCategory] = useState<OptionType | null>(
    null
  );
  const [searchKeyword, setSearchKeyword] = useState(
    decodeURIComponent(searchKeyWordParam || searchSKeyWordParam || "")
  );
  const [searchCategories, setsearchCategories] = useState<any[]>([]);
  const [searchCategoryLoading, setsearchCategoryLoading] = useState(false);

  const delayedFetchOptions = useCallback(
    debounce((searchText: string) => {
      getSearchCategories(searchText);
    }, 500),
    []
  );

  const getSearchCategories = async (search: string | null) => {
    setsearchCategoryLoading(true);
    await apiSearchProductCategory(search)
      .then((res) => {
        setsearchCategories(
          (res.data?.message?.data || []).map((c: any) => ({
            value: c.name,
            label: c.category_name,
          }))
        );
      })
      .finally(() => {
        setsearchCategoryLoading(false);
      });
  };

  const onClickCategory = (option: OptionType) => {
    setSelectedCategory(option);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      const firstCategory = searchCategories[0];
      history.push(
        DIRECT_PATHS.searchSupplier(firstCategory?.label || searchKeyword)
      );
    }
  };

  const getButtonHref = (type: "supplier" | "buyer") => {
    const choosingCategory = searchKeyword === selectedCategory?.label;
    const encodeSearchKeyword = encodeURIComponent(searchKeyword);
    if (type === "supplier") {
      return choosingCategory
        ? DIRECT_PATHS.categorySupplier(
            selectedCategory.value,
            undefined,
            encodeSearchKeyword
          )
        : DIRECT_PATHS.searchSupplier(encodeSearchKeyword);
    }
    if (type === "buyer") {
      return choosingCategory
        ? DIRECT_PATHS.categoryBuyer(
            selectedCategory.value,
            encodeSearchKeyword
          )
        : DIRECT_PATHS.searchBuyer(encodeSearchKeyword);
    }
    return "/";
  };

  return (
    <StyledForm
      className="search-box"
      spacing={{ xs: 1, md: 2 }}
      direction={{ xs: "column", md: "row" }}
      {...props}
    >
      <StyledAutocomplete
        freeSolo
        value={selectedCategory}
        options={searchCategories}
        getOptionLabel={(option: any) => option.label || ""}
        loading={searchCategoryLoading}
        onChange={(e, newValue: any) => {
          onClickCategory(newValue);
        }}
        inputValue={searchKeyword}
        onInputChange={(e, newInputValue) => {
          setSearchKeyword(newInputValue);
          delayedFetchOptions(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("what_are_you_looking_for")}
            fullWidth
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="search-icon" />
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Row spacing={1} className="buttons">
        <ALink href={getButtonHref("buyer")}>
          <GreenButton className="search-btn">{t("find_buyers")}</GreenButton>
        </ALink>

        <Link to={getButtonHref("supplier")}>
          <GreenButton className="search-btn">
            {t("find_suppliers")}
          </GreenButton>
        </Link>
      </Row>
    </StyledForm>
  );
};

export const StyledForm = styled(Stack)(({ theme }) => ({
  flex: 1,
  // maxWidth: 520,
  ".search-input": {
    maxWidth: 430,
  },
  ".MuiInputBase-root": {
    borderRadius: 8,
    "& input": {
      padding: "9px 16px",
      paddingLeft: 8,
      color: "#667085",
      fontSize: 14,
      lineHeight: "18pxx",
    },
    "& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
      borderColor: COLORS.green[500],
    },
  },
  "& .MuiSelect-icon": {
    display: "none",
  },
  ".buttons": {
    button: {
      whiteSpace: "nowrap",
      fontWeight: 500,
    },
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    ".search-input": {
      maxWidth: "100%",
    },
    ".buttons": {
      a: {
        flex: 1,
      },
      button: {
        width: "100%",
        fontSize: 12,
      },
    },
  },
}));

export default HeaderSearch;
