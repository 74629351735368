import { ButtonProps, styled } from "@mui/material";
import { APP_ICONS } from "Assets/icons";
import { ButtonBase } from "Components/Button.styles";
import { Img } from "Components/Img";
import { COLORS } from "constants/layout";
import { PATHS } from "constants/routes";
import { useTranslation } from "hooks/useTranslation";
import { Link } from "react-router-dom";

const GetQuotesButton = (props: ButtonProps) => {
  const t = useTranslation();
  return (
    <Link to={PATHS.postRfq}>
      <StyledGetQuotesButton
        variant="text"
        startIcon={<Img src={APP_ICONS.postRfq} />}
        {...props}
      >
        {t("get_quotes")}
      </StyledGetQuotesButton>
    </Link>
  );
};

const StyledGetQuotesButton = styled(ButtonBase)({
  fontSize: 14,
  fontWeight: 500,
  color: COLORS.yellow[500],
  padding: 6,
});

export default GetQuotesButton;
