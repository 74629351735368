import { IconButton, Stack, styled } from "@mui/material";
import { Client } from "@twilio/conversations";
import { ChatIcon } from "Assets/icons";
import { Row, SmTextMd } from "Components";
import useChat from "hooks/useChat";
import { lazy, memo, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { onCloseChatContainer, onOpenChatContainer } from "redux/actions/chat";
import {
  getChatClient,
  getConversations,
  getOpenChatContainer,
} from "redux/selectors/chat";
import { AppConversationType } from "types/chat";
import { CHAT_LAYOUT, COLORS } from "constants/layout";
import { UpChevronIcon } from "Assets/icons/chevron.icon";
import { SEARCH_PARAMS } from "constants/routes";
import { useQueryParam } from "helpers/search";
import useBreakpoints from "hooks/useBreakpoint";
import { withLoading } from "hocs/withLoading";

const ChatContainer = withLoading(lazy(() => import("./ChatContainer")));
const ChatContainerMobile = withLoading(
  lazy(() => import("./ChatContainerMobile"))
);

type Props = {};

const ChatToggleBar = (props: Props) => {
  const t = useIntl();
  const { isMobile } = useBreakpoints();
  const dispatch = useDispatch();
  const chatClient: Client = useSelector(getChatClient);
  const openChatContainer = useSelector(getOpenChatContainer);
  const { onOpenConversation } = useChat() || {};
  const openConvParam = useQueryParam(SEARCH_PARAMS.openConv);
  const conversations: AppConversationType[] =
    useSelector(getConversations) || [];

  const totalUnreadCount = useMemo(() => {
    const messagesCount = conversations.reduce(
      (total, conv) => total + (conv.unreadMessagesCount || 0),
      0
    );
    const conversationsCount = conversations.filter(
      (conversation) => (conversation.unreadMessagesCount || 0) > 0
    ).length;
    return {
      messagesCount,
      conversationsCount,
    };
  }, [conversations]);

  const onClickConversation = async (conversationId: string) => {
    onOpenConversation(conversationId);
  };

  const onClickOpenChatContainer = () => dispatch(onOpenChatContainer());

  const onClickCloseChatContainer = () => {
    dispatch(onCloseChatContainer());
  };

  useEffect(() => {
    const onOpenChatAuto = async () => {
      if (openConvParam && chatClient) {
        const paramConvs = openConvParam.split(",");
        await onOpenConversation(paramConvs[0]);
      }
    };
    onOpenChatAuto();
  }, [openConvParam, chatClient]);

  // Mobile view
  if (isMobile) {
    return (
      <>
        <StyledIconButton onClick={onClickOpenChatContainer}>
          <ChatIcon stroke="#fff" width={36} height={36} />
          {totalUnreadCount.messagesCount > 0 && (
            <p className="badge-number-sm">{totalUnreadCount.messagesCount}</p>
          )}
        </StyledIconButton>
        <ChatContainerMobile
          open={openChatContainer.open}
          onClose={onClickCloseChatContainer}
          {...{ totalUnreadCount, conversations }}
        />
      </>
    );
  }

  // Desktop view
  return (
    <StyledChatToggleBar
      direction="row"
      maxWidth={
        openChatContainer.open
          ? CHAT_LAYOUT.expandedChatContainerWidth
          : CHAT_LAYOUT.chatContainerWidth
      }
    >
      {!openChatContainer.open && (
        <Row
          className="bar"
          onClick={onClickOpenChatContainer}
          justifyContent="space-between"
        >
          <Row spacing={1}>
            <ChatIcon width={18} />
            <SmTextMd>{t.formatMessage({ id: "message" })}</SmTextMd>
            {totalUnreadCount.messagesCount > 0 && (
              <p className="badge-number-sm">
                {totalUnreadCount.messagesCount}
              </p>
            )}
          </Row>
          <UpChevronIcon />
        </Row>
      )}

      {openChatContainer.open && (
        <ChatContainer
          {...{ totalUnreadCount, conversations, onClickConversation }}
        />
      )}
    </StyledChatToggleBar>
  );
};

const StyledIconButton = styled(IconButton)({
  "&,:hover": {
    backgroundColor: COLORS.green[600],
  },
  width: 60,
  height: 60,
  position: "fixed",
  bottom: 74,
  right: 16,
  zIndex: 1,
  boxShadow: "1px -1px 3px 0 rgba(0,0,0,.15)",
  ".badge-number-sm": {
    position: "absolute",
    fontSize: 14,
    lineHeight: "12px",
    padding: "3px 4px",
    top: 12,
    right: 8,
    minWidth: 12,
    fontWeight: 600,
  },
});

const StyledChatToggleBar = styled(Stack)({
  borderRadius: 8,
  position: "fixed",
  bottom: 0,
  right: 48,
  boxShadow:
    "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  display: "flex",
  overflow: "hidden",
  width: "100%",
  backgroundColor: "#fff",
  border: `1px solid ${COLORS.gray[200]}`,
  zIndex: 10,
  ".bar": {
    cursor: "pointer",
    padding: "14px 16px",
    width: "100%",
  },
  ".chat-container": {
    height: 516,
    width: "100%",
    ".chat-detail": {
      flex: 1,
      borderRight: `1px solid ${COLORS.gray[200]}`,
    },
    ".chat-conversations": {
      maxWidth: CHAT_LAYOUT.chatContainerWidth,
      flex: 1,
    },
  },
});

export default memo(ChatToggleBar);
