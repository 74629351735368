import { Dialog, Divider, IconButton, NoSsr } from "@mui/material";

import { StyledCenterStack, StyledHeader } from "./Header.styles";
import LogoFreshdi from "../LogoFreshdi";
import { lazy, memo, useEffect, useState } from "react";
import Row from "../Row";
import { Container } from "../Container";
import {
  HeaderServices,
  SelectLanguage,
  TextSemibold,
  XlTextSemiBold,
} from "Components";
import useAuth from "hooks/useAuth";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { useIntl } from "react-intl";
import { COLORS } from "constants/layout";
import AuthBar from "../infos/AuthBar";
import {
  GetQuotesButton,
  RegisterButton,
  SwitchModeButton,
} from "Components/button";
import { withLoading } from "hocs/withLoading";
import { LgMenuIcon } from "Assets/icons";
const MobileUserHeader = withLoading(lazy(() => import("./MobileUserHeader")));

const Header = () => {
  const t = useIntl();
  const { isMobile } = useMediaBreakpoints();
  const { isLoggedIn } = useAuth();
  const [showDialog, setShowDialog] = useState(false);
  const [isBottomBoxVisible, setIsBottomBoxVisible] = useState(true);

  const onClickMenu = () => {
    setShowDialog(true);
  };

  const onCloseDrawer = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsBottomBoxVisible(false);
      } else {
        setIsBottomBoxVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <NoSsr>
      <StyledHeader>
        {/* Top header */}
        <Container>
          <StyledCenterStack spacing={1} justifyContent="space-between">
            <div style={{ width: isMobile ? 54 : 0 }}>
              {isMobile && (
                <IconButton onClick={onClickMenu} className="fit">
                  <LgMenuIcon />
                </IconButton>
              )}
            </div>
            {isMobile && (
              <Row spacing={0.5}>
                <LogoFreshdi />
                <TextSemibold
                  fontSize={10}
                  fontStyle="italic"
                  color={COLORS.green[600]}
                >
                  {t.formatMessage({ id: "buyer_workspace" })}
                </TextSemibold>
              </Row>
            )}
            {isMobile && (
              <Row spacing={0.5} justifyContent="flex-end">
                <SelectLanguage
                  buttonProps={{ sx: { justifyContent: "flex-start" } }}
                  showLabel={false}
                />
                <AuthBar hideUserName />
              </Row>
            )}

            {!isMobile && (
              <>
                <Row spacing={2} flex={1}>
                  <LogoFreshdi />
                  <XlTextSemiBold fontStyle="italic" color={COLORS.green[600]}>
                    {t.formatMessage({ id: "buyer_workspace" })}
                  </XlTextSemiBold>
                  <SwitchModeButton />
                </Row>
                <StyledCenterStack justifyContent="flex-end">
                  <GetQuotesButton />
                  <SelectLanguage buttonProps={{ className: "app-link-btn" }} />
                  <Row ml={1}>
                    <AuthBar />
                    {!isLoggedIn && <RegisterButton />}
                  </Row>
                </StyledCenterStack>
              </>
            )}
          </StyledCenterStack>
        </Container>

        {/* Bottom header */}
        {!isMobile && (
          <>
            <Divider sx={{ mt: 1.5 }} />
            <Container
              className={
                "bottom-box " + (isBottomBoxVisible ? "visible" : "hidden")
              }
            >
              <Row justifyContent="space-between">
                <div />
                <HeaderServices />
              </Row>
            </Container>
          </>
        )}
        <Dialog fullScreen open={showDialog} onClose={onCloseDrawer}>
          <MobileUserHeader onClose={onCloseDrawer} />
        </Dialog>
      </StyledHeader>
    </NoSsr>
  );
};

export default memo(Header);
