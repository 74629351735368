import { Stack } from "@mui/material";
import { Img } from "../Img";
import { forwardRef, useMemo } from "react";
import { XsText, XsTextMd } from "../Text";
import { useIntl } from "react-intl";
import ListingChip from "../chips/ListingChip";
import { COLORS } from "constants/layout";
import { DIRECT_PATHS } from "constants/routes";
import { BuyerInfosType, BuyerType } from "types/buyer";
import StyledBuyer from "./Buyer.styles";
import BaseBuyerInfors from "Components/infos/BaseBuyerInfors";
import useBreakpoints from "hooks/useBreakpoint";
import { formatImg } from "utils/helpers";
import { ALink } from "Components/link";

type Props = {
  buyer?: BuyerType;
};

const Buyer = forwardRef(({ buyer }: Props, ref: any) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoints();

  const {
    company_logo,
    num_posted_rfq = 0,
    business_types,
    is_seen,
    activity_tags = [],
    import_countries,
    customer_name = "",
    customer_id = "",
    country_code,
    country,
    last_active = "",
    is_verified = 0,
    categories = [],
    gallery_preview = [],
    num_employees,
    annual_revenue_amount,
    year_established,
    payment_term,
    trade_term,
    destination_port,
  } = buyer || {};

  const baseInfos: BuyerInfosType = {
    customer_name,
    customer_id,
    country_code,
    country,
    company_logo,
    activity_tags,
    last_active,
    is_verified,
  };

  const infors = useMemo(
    () =>
      [
        { label: "business_types", value: business_types },
        { label: "import_countries", value: import_countries },
        { label: "number_of_employees", value: num_employees },
        { label: "annual_revenue_amount", value: annual_revenue_amount },
        { label: "year_established", value: year_established },
        { label: "prefer_payment_term", value: payment_term },
        { label: "prefer_trade_term", value: trade_term },
        { label: "prefer_desctination_term", value: destination_port },
      ].filter((info) => info.value),
    [buyer]
  );

  return (
    <StyledBuyer ref={ref} className={is_seen ? "is-seen-box" : ""}>
      <Stack className="info-box" spacing={1}>
        <BaseBuyerInfors company={baseInfos} />
        {num_posted_rfq > 0 && (
          <XsTextMd className="status-label orange-label">
            {intl.formatMessage(
              { id: `qlt_rfq${num_posted_rfq > 1 ? "s" : ""}_posted` },
              { quantity: num_posted_rfq }
            )}
          </XsTextMd>
        )}

        {/* Text infos stack */}
        <Stack spacing={0.5}>
          {(categories || []).length > 0 && (
            <XsText
              color={COLORS.green[600]}
              className="categories-text cutoff-text twoline"
              component="h3"
            >
              {intl.formatMessage({ id: "buyer_of" })}:{" "}
              {categories.map((category, i) => (
                <ALink href={DIRECT_PATHS.categoryBuyer(category.name)} key={i}>
                  {category.category_name}
                  {i === categories.length - 1 ? "" : ", "}
                </ALink>
              ))}
            </XsText>
          )}

          <Stack direction="row" flexWrap="wrap" gap={1}>
            {infors.map(({ value, label }, i) => (
              <XsText
                className="info-text ellipsis-text"
                key={i}
                color={COLORS.gray[500]}
              >
                {intl.formatMessage({ id: label }) + ": "} <span>{value}</span>
              </XsText>
            ))}
          </Stack>
        </Stack>

        {/* Buyer activity tags */}
        <ListingChip tags={activity_tags} />

        {isMobile && gallery_preview.length > 0 && (
          <div className="scroll-box medias">
            {gallery_preview.map((media, i) => (
              <Img src={formatImg(media, 3)} alt="media" key={i} />
            ))}
          </div>
        )}
      </Stack>

      {/* RFQ list and gallery */}
      {/* {!isMobile && (
        <Stack direction="row" gap={1} className="right-box">
          {gallery_preview.length > 0 && (
            <Link to={`/${slug}`} rel={followRel(no_follow)} className="media">
              <Swiper
                className="medias"
                modules={[Pagination]}
                pagination={{
                  clickable: true,
                }}
              >
                {gallery_preview.map((media, i) => (
                  <SwiperSlide key={i}>
                    <Img
                      src={formatImg(media, 3)}
                      alt="media"
                      height="100%"
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Link>
          )}
        </Stack>
      )} */}
    </StyledBuyer>
  );
});

export default Buyer;
