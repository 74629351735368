import { Box, Stack, StackProps, styled } from "@mui/material";
import Footer from "Components/Footer";
import UserHeader from "Components/header/UserHeader";
import DesktopUserBar from "Components/bar/DesktopUserBar";
import Main from "Components/Main";
import { MENU_WIDTH, SCROLL_TOP_POSITIONS } from "constants/layout";
import useBreakpoints from "hooks/useBreakpoint";
import { ReactNode, Suspense, useEffect, useState } from "react";
import LoadingSkeleton from "Components/skeleton/LoadingSkeleton";
import { useHistory } from "react-router-dom";
import { useQueryParam } from "helpers/search";
import { PATHS, SEARCH_PARAMS } from "constants/routes";
import { getChatClient } from "redux/selectors/chat";
import { useSelector } from "react-redux";
import useChat from "hooks/useChat";

type Props = {
  children?: ReactNode;
  mainprops?: StackProps;
};

const UserLayout = (props: Props) => {
  const { children } = props;
  const history = useHistory();
  const { isMobile } = useBreakpoints();
  const openConvParam = useQueryParam(SEARCH_PARAMS.openConv);
  const chatClient = useSelector(getChatClient);
  const { onOpenConversation } = useChat();

  const [showFullMenu, setShowFullMenu] = useState(isMobile ? false : true);

  const onToggleShowFull = () => {
    setShowFullMenu(!showFullMenu);
  };

  useEffect(() => {
    const onOpenChatAuto = async () => {
      if (openConvParam && chatClient) {
        const paramConvs = openConvParam.split(",");
        await onOpenConversation(paramConvs[0]);
        history.push(PATHS.messages);
      }
    };
    onOpenChatAuto();
  }, [openConvParam, chatClient]);

  return (
    <>
      <UserHeader />
      <Main {...props.mainprops}>
        <StyledStack direction="row" spacing={2}>
          {!isMobile && (
            <DesktopUserBar {...{ showFullMenu, onToggleShowFull }} />
          )}

          <Box
            width={`calc(100% - ${
              isMobile
                ? 0
                : (showFullMenu ? MENU_WIDTH.expanded : MENU_WIDTH.collaped) +
                  16
            }px)`}
          >
            <Suspense fallback={<LoadingSkeleton />}>{children}</Suspense>
          </Box>
        </StyledStack>
      </Main>
      <Footer />
    </>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  ".header": {
    position: "sticky",
    top: SCROLL_TOP_POSITIONS.desktopHeader,
    padding: "16px 0",
    zIndex: 1,
    marginTop: -8,
    backgroundColor: "var(--bg-color)",
  },
  [theme.breakpoints.down("md")]: {
    ".header": {
      position: "relative",
      top: 0,
    },
  },
}));

export default UserLayout;
