import { withLoading } from "hocs/withLoading";
import { lazy } from "react";

export { default as LogoFreshdi } from "./LogoFreshdi";
export { default as Wait } from "./Wait";
export { default as ScrollTopButton } from "./ScrollTopButton";
export { default as DynamicMedia } from "./DynamicMedia";
export { default as DynamicThumbnail } from "./DynamicThumbnail";
export { default as SelectLanguage } from "./SelectLanguage";
export { default as LoadedImage } from "./LoadedImage";
export { default as CustomBreadcrumb } from "./CustomBreadcrumb";
export { default as Footer } from "./Footer";
export { default as Header } from "./header/Header";
export * from "./Img";
export { default as Main } from "./Main";
export { default as Product } from "./Product";
export { default as Company } from "./card/Company";
export { default as Swiper } from "./Swiper";
export { default as Pagnigation } from "./Pagnigation";
export { default as Layout } from "./layout";
export { default as LoadingSkeleton } from "./skeleton/LoadingSkeleton";
export { default as IntlMsg } from "./IntlMsg";
export { default as TextOnLine } from "./TextOnLine";
export * from "./Button";
export * from "./CircularLoading";
export * from "./ErrorText";
export * from "./SnackbarNotify";
export { default as Row } from "./Row";
export { default as Menu } from "./Menu";
export { default as Tabs } from "./Tabs";
export { default as HtmlViewer } from "./HtmlViewer";
export * from "./Input";
export { default as RFQStatus } from "./RFQStatus";
export { default as TimeDiff } from "./TimeDiff";
export { default as StyledRFQHeader } from "./StyledRFQHeader";
export { default as HeaderServices } from "./header/HeaderServices";
export { default as ItemList } from "./ItemList";
export { default as Attachment } from "./Attachment";
export { default as CountryFlag } from "./CountryFlag";
export { default as Breadcrumbs } from "./Breadcrumbs";
export { default as SuggestProduct } from "./SuggestProduct";
export const Categories = withLoading(lazy(() => import("./Categories")));
export { default as Blocker } from "./Blocker";
export { default as Buyer } from "./card/Buyer";
export { default as UploadFiles } from "./UploadFiles";
export * from "./Text";
export const ChipList = withLoading(lazy(() => import("./ChipList"), 0));
export { default as Disclaimer } from "./Disclaimer";
export const RFQ = lazy(() => import("./card/RFQ"));
export { default as OTPInput } from "./OTPInput";
export * from "./Tooltip";
export { default as Post } from "./Post";
export const RelatedSearchesComponent = lazy(() => import("./RelatedSearches"));
export const RelatedSearches = withLoading(RelatedSearchesComponent);
export const Quotation = lazy(() => import("./card/Quotation"));
export const Notification = withLoading(lazy(() => import("./Notification")));
export { default as Popover } from "./Popover";
export const Chat = withLoading(lazy(() => import("./chat/Chat")));
export { default as SimpleHeader } from "./header/SimpleHeader";
export const SwitchAccountPopup = lazy(() => import("./SwitchAccountPopup"));
export const ReportForm = withLoading(lazy(() => import("./form/ReportForm")));
export * from "./Checkbox";
export * from "./Autocomplete";
export * from "./TipBanners";
export * from "./AnimationText";
export const SendInquiryForm = withLoading(
  lazy(() => import("./form/SendInquiryForm"))
);
export { default as AIGenerateInput } from "./input/AIGenerateInput";
export { default as ProductSkeleton } from "./skeleton/ProductSkeleton";
export { default as Select } from "./input/Select";
export * from "./input/Autocomplete.styles";
export { default as SendInquiryButton } from "./button/SendInquiryButton";
