import { SVGProps } from "react";

export function SmEditIcon({
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox={"0 0 15 15"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_17619_7384)">
        <path
          d="M6.875 2.49306H2.5C2.16848 2.49306 1.85054 2.62476 1.61612 2.85918C1.3817 3.0936 1.25 3.41154 1.25 3.74306V12.4931C1.25 12.8246 1.3817 13.1425 1.61612 13.3769C1.85054 13.6114 2.16848 13.7431 2.5 13.7431H11.25C11.5815 13.7431 11.8995 13.6114 12.1339 13.3769C12.3683 13.1425 12.5 12.8246 12.5 12.4931V8.11806M11.5625 1.55556C11.8111 1.30692 12.1484 1.16724 12.5 1.16724C12.8516 1.16724 13.1889 1.30692 13.4375 1.55556C13.6861 1.8042 13.8258 2.14143 13.8258 2.49306C13.8258 2.84469 13.6861 3.18192 13.4375 3.43056L7.5 9.36806L5 9.99306L5.625 7.49306L11.5625 1.55556Z"
          stroke={stroke}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17619_7384">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0 -0.00695801)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
