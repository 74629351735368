import { ReactNode } from "react";
import { LoadingProvider } from "./loading";
import NotifyProvider from "./notify";
import { CommonDataProvider } from "./common-data";
import { LayoutProvider } from "./layout";
import NotificationHandler from "helpers/notification";
import ScrollToTop from "Components/ScrollToTop";
import ChatProvider from "./chat";
import { FloatingActionBar } from "Components/bar";

const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <CommonDataProvider>
      <ChatProvider>
        <LayoutProvider>
          <LoadingProvider>
            <NotifyProvider>
              {children}

              {/* Scroll top */}
              <ScrollToTop />

              {/* Float action bar */}
              <FloatingActionBar />
              <NotificationHandler />
            </NotifyProvider>
          </LoadingProvider>
        </LayoutProvider>
      </ChatProvider>
    </CommonDataProvider>
  );
};

export default AppProvider;
