import { objectToQueryString } from "helpers/search";
import {
  BIZ_DOMAIN,
  BUYER_CENTER_DOMAIN,
  LANDING_DOMAIN,
  SOURCING_DOMAIN,
  SUPPLIER_CENTER_DOMAIN,
  TRACE_DOMAIN,
} from "./schemas";

export const PATHS = {
  home: "/",
  topItems: "/top-items",
  farms: "/farms",
  search: "/search",
  searchSuppliers: "/search-supplier",
  categorySupplier: "/supplier-category",
  oldCategory: "/category",
  category: "/product-category",
  allItems: "/all-items",
  login: "/login",
  resetPassword: "/reset-pw",
  updatePassword: "/update-password",
  register: "/register",
  registerSupplier: `/register?acc-type=Seller`,
  profile: "/user/profile",
  messages: "/user/messages",
  setting: "/user/setting",
  overview: "/user/overview",
  requestQuotation: "/user/request-quotation",
  receivedQuotation: "/user/received-quotation",
  orderHistory: "/user/order-history",
  inquiries: "/user/inquiries",
  loyaltyPoint: "/user/loyalty-point",
  membership: "/user/membership",
  voucher: "/user/voucher",
  wallet: "/user/wallet",
  address: "/user/address",
  analytics: "/user/analytics",
  postRfq: "/quick-rfq",
  rfqDetail: `/request`,
  supplier: "/supplier",
  item: "/product",
  notFound: "/404",
  sitemap: "/help/sitemap",
  policy: `/privacy-policy`,
  pricing: `/membership-pricing`,
  intro: `${LANDING_DOMAIN}/vi/tham-gia-trung-bay-san-pham-nong-san`,
  cart: (path: string) => `${path}?popup=cart`,
  sendInquiry: "/send-inquiry",
  activeAccount: "/activate-account",
  contactUs: `/contact-us`,
  smartTrace: `${LANDING_DOMAIN}/smart-traceability-solution`,
  termCondition: `/terms-of-use`,
  products: "/products",
  productsFull: TRACE_DOMAIN + "/products",
  buyerPricing: `${LANDING_DOMAIN}/membership/membership-for-buyer`,
  supplierPricing: `/membership-pricing`,
  freshdiPromotion: `${LANDING_DOMAIN}/resources/premium-package-offered-to-the-first-500-customers-who-register`,
  supplierPricingVi: `${LANDING_DOMAIN}/vi/bieu-phi/danh-cho-nha-cung-cap`,
  suppliers: "/suppliers",
  suppliersFull: TRACE_DOMAIN + "/suppliers",

  paymentBuyer: "/buyer-premium-payment",
  paymentSupplier: "/supplier-premium-payment",
  activeAccountBuyer: "/activate-account-buyer",
  user: "/user",
  quickRegister: "/quick-register",
  unsubscribe: "/unsubscribe",
  joinCampaign: "/join-campaign",
  disclaimers: `/disclaimers`,
  complaintCenter: `${LANDING_DOMAIN}/agreement-on-use-of-complaint-center`,
  newArrivals: "/new-product",
  freshdiChoice: "/hot-price-product",
  paymentPending: "/payment-details",
  freshdiSchedule: "https://freshdi.fillout.com/schedule",
  intellectualPropertyPolicy: "/intellectual-property-policy",
  trustStar: "/trust-star",

  buyerCenter: BUYER_CENTER_DOMAIN,
  supplierCenter: `${SUPPLIER_CENTER_DOMAIN}/freshdi.com-1`,
  buyerFAQs: `${BUYER_CENTER_DOMAIN}/faqs`,
  supplierFAQs: `${SUPPLIER_CENTER_DOMAIN}/faqs-1`,

  buyleads: `${SOURCING_DOMAIN}/buyleads`,
  buyers: SOURCING_DOMAIN + "/buyers",
  buyerDetail: SOURCING_DOMAIN + "/buyer",
  categoryBuyer: `${SOURCING_DOMAIN}/buyer-category`,
  supplierProducts: `${BIZ_DOMAIN}/products`,
  supplierCreateProduct: `${BIZ_DOMAIN}/products/create`,
  supplierMembership: `${BIZ_DOMAIN}/membership`,
  supplierMyQuotations: `${BIZ_DOMAIN}/my-quotations`,
  supplierInquiries: `${BIZ_DOMAIN}/inquiries`,
  supplierProfile: `${BIZ_DOMAIN}/profile`,
  supplierSetting: `${BIZ_DOMAIN}/setting`,
  supplierDesk: `${BIZ_DOMAIN}/desk`,
  bizDomain: BIZ_DOMAIN,
};

export const DIRECT_PATHS = {
  requestQuotationDetail(id: string) {
    return `${PATHS.requestQuotation}/detail/${id}`;
  },
  requestQuotationEdit(id: string) {
    return `${PATHS.requestQuotation}/${id}`;
  },
  rfqDetail(id: string, fullLink: boolean = true) {
    return `${fullLink ? SOURCING_DOMAIN : ""}${PATHS.rfqDetail}/${id}`;
  },
  category(id: string, country?: string) {
    return `${PATHS.category}${country ? `/${country}` : ""}/${id}`;
  },
  categoryRfq(category?: string) {
    return `${PATHS.buyleads}/${category}`;
  },
  categorySupplier(id: string, country?: string, search?: string) {
    return `${PATHS.categorySupplier}${country ? `/${country}` : ""}/${id}${
      search ? `?${SEARCH_PARAMS.sKeyword}=${search}` : ""
    }`;
  },
  companyDetail(id?: string) {
    return `${PATHS.supplier}/${id}`;
  },
  itemDetail(id?: string) {
    return `${PATHS.item}/${id}`;
  },
  buyerDetail(id?: string) {
    return `${PATHS.buyerDetail}/${id}`;
  },
  supplierDetail(id?: string) {
    return `/supplier/${id}`;
  },
  categoryBuyer(id?: string, search?: string) {
    return `${PATHS.categoryBuyer}/${id}${
      search ? `?${SEARCH_PARAMS.sKeyword}=${search}` : ""
    }`;
  },
  receivedQuotationDetail(id?: string) {
    return `${PATHS.receivedQuotation}/${id}`;
  },
  newArrivals(id?: string) {
    return `${PATHS.newArrivals}/${id}`;
  },
  freshdiChoice(id?: string) {
    return `${PATHS.freshdiChoice}/${id}`;
  },
  paymentPending(id?: string) {
    return `${PATHS.paymentPending}/${id}`;
  },
  paymentDetail(id?: string) {
    return `${PATHS.paymentSupplier}/${id}`;
  },
  inquiryDetail(id: string = "") {
    return `${PATHS.inquiries}/${id}`;
  },
  searchProduct(search?: string) {
    return `${PATHS.search}${
      search ? `?${SEARCH_PARAMS.keyword}=${search}` : ""
    }`;
  },
  searchSupplier(search?: string) {
    return `${PATHS.searchSuppliers}${
      search ? `?${SEARCH_PARAMS.keyword}=${search}` : ""
    }`;
  },
  searchBuyer(search?: string) {
    return `${PATHS.buyers}${
      search ? `?${SEARCH_PARAMS.keyword}=${search}` : ""
    }`;
  },
};

export const SEARCH_PARAMS = {
  category_id: "category_id",
  s_category_id: "s_category_id",
  page: "page",
  keyword: "keyword",
  sKeyword: "s_keyword",
  directUrl: "direct-url",
  uid: "uid",
  searchType: "search-type",
  key: "key",
  rfq_id: "rfq-id",
  farmId: "supp",
  itemId: "prod",
  tab: "tab",
  quantity: "qlt",
  unit: "u",
  startDate: "srtdate",
  endDate: "enddate",
  plan: "plan",
  mail: "ml",
  username: "un",
  rfqId: "rfq-id",
  messageId: "message-id",
  quotationId: "quotation-id",
  accountType: "acc-type",
  inquiryId: "inquiry-id",
  productId: "product_id",
  utmSource: "utm_source",
  is_verified: "is_verified",
  countries: "countries",
  premium: "premium",
  openConv: "open-conv",
  action: "action",
  popup: "popup",
};

export const FRESHDI_SUBDOMAIN = ["tr", "trace", "freshdi"];

export const SEARCH_PARAM_VALUES = {
  scrollToPostRfq: "rfqf",
  accountTypeBiz: "Seller",
  tabPost: "post",
  tabProduct: "product",
  tabReview: "review",
  actionVerifiedRfq: "verified_rfq",
  actionDeclineRfq: "decline_rfq",
  popupReview: "review",
  popupComplaint: "report",
};
