import { DIRECT_PATHS } from "constants/routes";
import useGetData from "hooks/useGetData";
import useNavigate from "hooks/useNavigate";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMenuCountData } from "redux/actions/common-data";
import { reloadMenuCountDataSelector } from "redux/selectors/common";
import { getUser } from "redux/selectors/user";
import { apiGetCountNotiMenuBar } from "services/api/notification";
import { apiGetAppConfigs } from "services/api/helpers";
// import { useParams } from "react-router-dom";
import { BreadscrumbType } from "types/breadscrumb";
import { AppConfigsDataType, AppConfigsType } from "types/common";

const CommonDataContext = createContext<{
  selectedCategory?: string;
  appConfigs?: AppConfigsType;
  setSelectedCategory: Dispatch<SetStateAction<string | undefined>>;
  onChangeSelectedCategory: (category: string, openNewTab?: boolean) => void;
  onChangeBreadscrumbs: (breadscumbs?: BreadscrumbType[]) => void;
  breadscumbs?: BreadscrumbType[];
  appConfigLoading?: boolean;
}>({
  selectedCategory: undefined,
  setSelectedCategory: () => {},
  onChangeSelectedCategory: () => {},
  onChangeBreadscrumbs: () => {},
  breadscumbs: [],
  appConfigLoading: false,
});

export const CommonDataProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { history } = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser) || {};

  const [selectedCategory, setSelectedCategory] = useState<
    string | undefined
  >();
  const [breadscumbs, setBreadscumbs] = useState<BreadscrumbType[]>([]);
  const reloadMenuCountData = useSelector(reloadMenuCountDataSelector);

  const { data: unSeenData } = useGetData({
    promise: () => (user.is_logged_in ? apiGetCountNotiMenuBar() : null),
    deps: [reloadMenuCountData, user.is_logged_in],
  });

  const onChangeSelectedCategory = (category: string, openNewTab?: boolean) => {
    setSelectedCategory(category);
    if (openNewTab) {
      window.open(DIRECT_PATHS.category(category));
    } else {
      history.push(DIRECT_PATHS.category(category));
    }
  };

  // App config data
  const { data: appConfigsData, isLoading: appConfigLoading } = useGetData({
    promise: apiGetAppConfigs,
    timeout: 1000,
  });

  const appConfigs = useMemo(() => {
    const {
      country = [],
      uom = [],
      popular_cate = [],
      all_cates = [],
      monthly_cate = [],
      stat,
    } = (appConfigsData?.data || {}) as AppConfigsDataType;
    const countries = country.map(
      ({ name, country_name, code, custom_calling_code }) => ({
        label: name,
        value: country_name,
        code,
        custom_calling_code,
      })
    );
    const uoms = uom.map(({ name, uom_name }) => ({
      label: uom_name,
      value: name,
    }));
    const popularCates = popular_cate.map(({ name, category_name }) => ({
      label: category_name,
      value: name,
    }));
    const allCates = all_cates.map(({ name, category_name }) => ({
      label: category_name,
      value: name,
    }));
    const monthlyCates = monthly_cate.map(({ name, category_name, image }) => ({
      label: category_name,
      value: name,
      image,
    }));
    return { countries, uoms, popularCates, monthlyCates, stat, allCates };
  }, [appConfigsData]);

  const onChangeBreadscrumbs = (breadscumbs?: BreadscrumbType[]) => {
    setBreadscumbs(breadscumbs || []);
  };

  useEffect(() => {
    if (unSeenData?.data) {
      dispatch(setMenuCountData(unSeenData?.data || {}));
    }
  }, [unSeenData, dispatch]);

  return (
    <CommonDataContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        onChangeSelectedCategory,
        onChangeBreadscrumbs,
        breadscumbs,
        appConfigs,
        appConfigLoading,
      }}
    >
      {children}
    </CommonDataContext.Provider>
  );
};

export const useCommonData = () => useContext(CommonDataContext);
