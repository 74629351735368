import { Stack } from "@mui/material";

import { StyledHeader, StyledMenuItem } from "./DesktopUserBar.styles";
import useNavigate from "hooks/useNavigate";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";
import { PATHS } from "constants/routes";
import { MENU_WIDTH } from "constants/layout";
import { SmText } from "Components";
import { limitCountNoti } from "helpers/string";
import { useIntl } from "react-intl";
import { useLayout } from "providers/layout";

const DesktopUserBar = ({
  showFullMenu,
  onToggleShowFull,
}: {
  showFullMenu?: boolean;
  onToggleShowFull?: () => void;
}) => {
  const t = useIntl();
  const { location } = useNavigate();
  const { userTabs, onClickTab } = useLayout();

  return (
    <StyledHeader
      width={showFullMenu ? MENU_WIDTH.expanded : MENU_WIDTH.collaped}
    >
      <Stack spacing={1}>
        <Stack spacing={1} alignItems={showFullMenu ? "flex-end" : "center"}>
          <StyledMenuItem onClick={onToggleShowFull} alignItems="flex-end">
            {showFullMenu ? <IoMdArrowBack /> : <IoMdArrowForward />}
          </StyledMenuItem>
        </Stack>

        {userTabs
          ?.filter((tab) => !tab.isNotTab)
          .map((tab: any, idx: number) => {
            let { path, count = 0 } = tab;
            let isSelected = location.indexOf(path) > -1 && path !== PATHS.home;
            let Icon = tab?.icon || null;

            return (
              <StyledMenuItem
                direction="row"
                justifyContent={showFullMenu ? "left" : "center"}
                alignItems="center"
                selected={isSelected}
                p={showFullMenu ? "10px 12px" : 1}
                onClick={() => {
                  if (tab?.event) {
                    tab.event();
                  } else {
                    onClickTab(tab);
                  }
                }}
                key={idx}
              >
                {Icon && (
                  <Icon color={isSelected ? "#027B30" : "var(--gray)"} />
                )}

                {showFullMenu && (
                  <SmText
                    className={`tab-label ellipsis-text ${
                      isSelected ? "selected" : ""
                    } ${showFullMenu ? "showfull" : ""}`}
                  >
                    {t.formatMessage({ id: tab?.label })}
                  </SmText>
                )}
                {Number(count) > 0 && (
                  <p
                    className={
                      "badge-number " + (showFullMenu ? "" : "absolute")
                    }
                  >
                    {limitCountNoti(count)}
                  </p>
                )}
              </StyledMenuItem>
            );
          })}
      </Stack>
    </StyledHeader>
  );
};

export default DesktopUserBar;
