import { combineReducers } from "redux";
import cartReducer from "./cart";
import itemReducer from "./item";
import userReducer from "./user";
import commonReducer from "./common";
import layoutReducer from "./layout";
import notificationReducer from "./notification";
import commonDataReducer from "redux/reducers/common-data";
import chatReducer from "redux/reducers/chat";

export default combineReducers({
  cartReducer,
  itemReducer,
  userReducer,
  commonReducer,
  layoutReducer,
  notificationReducer,
  commonDataReducer,
  chatReducer,
});
