import { Stack, styled } from "@mui/material";
import { MENU_WIDTH } from "constants/layout";

export const StyledHeader = styled(Stack)(({ theme }) => ({
  position: "sticky",
  top: 126,
  transition: "width 0.45s",
  height: "100%",
  backgroundColor: "#fff",
  zIndex: 1,
  padding: "32px 16px",
  "& .main": {
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& p.tab-label": {
    fontWeight: 500,
    transition: "all 0.5s",
    opacity: 0,
    visibility: "hidden",
    maxWidth: 0,
    "&.showfull": {
      opacity: 1,
      marginLeft: 16,
      visibility: "visible",
      maxWidth: MENU_WIDTH.expanded - 74,
    },
    "&.selected": {
      color: "#027B30",
    },
  },
  "& .tmp": {
    width: 32,
  },
  svg: {
    width: 24,
    height: 24,
    color: "var(--gray)",
  },
  ".switch-btn svg": {
    width: 18,
    height: 18,
  },
  [theme.breakpoints.down("md")]: {
    top: 72,
    padding: "16px 4px",
  },
}));

export const StyledMenuItem = styled(Stack)<{ selected?: boolean }>(
  ({ selected, theme }) => ({
    cursor: "pointer",
    borderRadius: 8,
    backgroundColor: selected ? "#EDFFF4" : "intitial",
    "& img": {
      width: 20,
    },
    position: "relative",
    ".badge-number": {
      marginLeft: 16,
      "&.absolute": {
        position: "absolute",
        marginLeft: 0,
        top: 4,
        right: 4,
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: "8px 10px",
    },
  })
);
