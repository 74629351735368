import { Button, Menu, styled } from "@mui/material";

interface StyledAuthButtonProps {
  hideUserName?: boolean;
}

export const StyledAuthButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "hideUserName",
})<StyledAuthButtonProps>(({ hideUserName, theme }) => ({
  textTransform: "initial",
  padding: 0,
  "p, svg": {
    color: "var(--gray-500)",
  },
  ".username": {
    maxWidth: 164,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ".status-label": {
    padding: "2px 8px",
  },
  ".MuiButton-startIcon": {
    marginRight: 8,
  },
  ".quote-text": {
    color: "#EB8000",
    fontWeight: 500,
    span: {
      fontWeight: 600,
    },
  },
  minWidth: hideUserName ? "fit-content" : 64,
  ".MuiAvatar-root": {
    width: 32,
    height: 32,
  },
  [theme.breakpoints.down("md")]: {
    paddingLeft: 4,
    ".username": { fontSize: 12 },
    ".MuiAvatar-root": {
      width: 24,
      height: 24,
    },
    ".MuiButton-startIcon": {
      marginRight: 0,
    },
  },
}));

export const StyledMenu = styled(Menu)({
  ".MuiPaper-root": {
    width: "100%",
    maxWidth: 284,
    borderRadius: 8,
  },
  p: {
    fontWeight: 500,
  },
  ".MuiList-root": {
    padding: 0,
  },
  ".MuiButtonBase-root": {
    borderRadius: 0,
    padding: "5px 16px",
    minHeight: 30,
  },
  ".badge-number": {
    marginLeft: 8,
  },
  ".user-info": {
    padding: 16,
    paddingBottom: 0,
    ".credit-text": {
      span: {
        fontWeight: 600,
      },
    },
    ".plan": {},
    ".avatar": {
      minWidth: 60,
      width: 60,
      height: 60,
      objectFit: "cover",
    },
  },
});
