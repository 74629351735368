import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  AnalyticArrowIcon,
  BagIcon,
  ChatIcon,
  CircleCheckIcon,
  DashBoardIcon,
  NoteIcon,
  SendIcon,
  SettingIcon,
} from "Assets/icons";
import { useSelector } from "react-redux";
import { menuCountDataSelector } from "redux/selectors/common";
import { PATHS } from "constants/routes";
import { UserTabType } from "types/layout";
import { useHistory } from "react-router-dom";
import { getConversations } from "redux/selectors/chat";
import { AppConversationType } from "types/chat";

const LayoutContext = createContext<{
  showFullMenu: boolean;
  onToggleShowFull: () => void;
  userTabs: UserTabType[];
  onClickTab: (tab: UserTabType) => void;
}>({
  showFullMenu: false,
  onToggleShowFull: () => {},
  userTabs: [],
  onClickTab: () => {},
});

export const LayoutProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const history = useHistory();
  const menuCountStoreData = useSelector(menuCountDataSelector);
  const conversations: AppConversationType[] =
    useSelector(getConversations) || [];

  const [showFullMenu, setShowFullMenu] = useState(false);

  const totalUnreadCount = useMemo(
    () =>
      conversations.reduce(
        (total, conv) => total + (conv.unreadMessagesCount || 0),
        0
      ),
    [conversations]
  );

  const userTabs: UserTabType[] = useMemo(
    () => [
      {
        label: "overview",
        path: PATHS.overview,
        icon: DashBoardIcon,
      },
      {
        label: "messages",
        path: PATHS.messages,
        icon: ChatIcon,
        count: totalUnreadCount,
      },
      {
        label: "buying_leads",
        icon: SendIcon,
        path: PATHS.requestQuotation,
      },
      {
        label: "received_quotations",
        icon: BagIcon,
        path: PATHS.receivedQuotation,
        count: menuCountStoreData?.count_quotations,
      },
      {
        path: `${PATHS.receivedQuotation}/:quotationId`,
        isNotTab: true,
      },
      {
        label: "inquiries",
        icon: NoteIcon,
        path: PATHS.inquiries,
      },
      {
        label: "setting",
        icon: SettingIcon,
        path: PATHS.profile,
      },
      {
        label: "membership",
        icon: CircleCheckIcon,
        path: PATHS.membership,
      },
      {
        label: "analytics",
        icon: AnalyticArrowIcon,
        path: PATHS.analytics,
      },
    ],
    [menuCountStoreData, totalUnreadCount]
  );

  const onToggleShowFull = useCallback(() => {
    setShowFullMenu(!showFullMenu);
  }, [showFullMenu]);

  const onClickTab = useCallback(
    (tab: UserTabType) => {
      history.push(tab?.path);
    },
    [history]
  );

  const contextData = useMemo(
    () => ({
      showFullMenu,
      onToggleShowFull,
      userTabs,
      onClickTab,
    }),
    [showFullMenu, onToggleShowFull, userTabs, onClickTab]
  );

  return (
    <LayoutContext.Provider value={contextData}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
