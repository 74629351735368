import axios from "axios";
import { API_DOMAIN } from "constants/schemas";
import { formatError } from "helpers/error";
import { showNotification } from "helpers/notification";

const http = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: TOKEN ? `token ${TOKEN}` : "",
    // Cookie: `utm_source=${getCookie('utm_source')}`
  },
  withCredentials: true,
});


http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorMsg = formatError(error)?.message || "Something went wrong";
    showNotification(errorMsg);
    return Promise.reject(error);
  }
);

export default http;
