import { SVGProps } from "react";

export function UserIcon({
  width = 40,
  height = 40,
  viewBox = "0 0 40 40",
  fill = "none",
  stroke = "#ADB3BC",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_3397_42434)">
        <path
          d="M19.9965 18.8876C21.5348 18.8876 23.0386 18.4314 24.3176 17.5768C25.5967 16.7222 26.5936 15.5074 27.1823 14.0862C27.7709 12.665 27.925 11.1012 27.6249 9.59244C27.3248 8.0837 26.584 6.69783 25.4962 5.61009C24.4085 4.52235 23.0226 3.78159 21.5139 3.48148C20.0052 3.18138 18.4413 3.3354 17.0201 3.92408C15.5989 4.51276 14.3842 5.50966 13.5295 6.78871C12.6749 8.06776 12.2188 9.57151 12.2188 11.1098C12.2188 13.1726 13.0382 15.1509 14.4968 16.6095C15.9554 18.0681 17.9337 18.8876 19.9965 18.8876ZM19.9965 5.55426C21.0953 5.55426 22.1694 5.88008 23.083 6.49054C23.9966 7.10099 24.7087 7.96865 25.1292 8.98379C25.5497 9.99894 25.6597 11.116 25.4453 12.1936C25.231 13.2713 24.7019 14.2612 23.9249 15.0382C23.1479 15.8151 22.158 16.3443 21.0804 16.5586C20.0027 16.773 18.8857 16.663 17.8705 16.2425C16.8554 15.822 15.9877 15.1099 15.3773 14.1963C14.7668 13.2827 14.441 12.2086 14.441 11.1098C14.441 9.63639 15.0263 8.22331 16.0682 7.18144C17.11 6.13957 18.5231 5.55426 19.9965 5.55426Z"
          fill="black"
        />
        <path
          d="M33.8547 27.0798C32.0728 25.1965 29.9256 23.6964 27.5442 22.6712C25.1628 21.646 22.5974 21.1172 20.0047 21.1172C17.412 21.1172 14.8466 21.646 12.4652 22.6712C10.0838 23.6964 7.93657 25.1965 6.15469 27.0798C5.76837 27.4925 5.55381 28.0368 5.55469 28.6021V34.4465C5.55469 35.0359 5.78882 35.6011 6.20556 36.0179C6.62231 36.4346 7.18754 36.6687 7.77691 36.6687H32.2214C32.8107 36.6687 33.376 36.4346 33.7927 36.0179C34.2095 35.6011 34.4436 35.0359 34.4436 34.4465V28.6021C34.4475 28.0383 34.237 27.4942 33.8547 27.0798ZM32.2214 34.4465H7.77691V28.5909C9.35161 26.9328 11.2472 25.6124 13.3484 24.7102C15.4496 23.8079 17.7124 23.3426 19.9991 23.3426C22.2859 23.3426 24.5487 23.8079 26.6498 24.7102C28.751 25.6124 30.6467 26.9328 32.2214 28.5909V34.4465Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3397_42434">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ProfileIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  color = "#667085",
  stroke = "none",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M14 8C14 9.06087 13.5786 10.0783 12.8284 10.8284C12.0783 11.5786 11.0609 12 10 12C8.93913 12 7.92172 11.5786 7.17157 10.8284C6.42143 10.0783 6 9.06087 6 8C6 6.93913 6.42143 5.92172 7.17157 5.17157C7.92172 4.42143 8.93913 4 10 4C11.0609 4 12.0783 4.42143 12.8284 5.17157C13.5786 5.92172 14 6.93913 14 8Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.592 19.992C4.2585 19.778 0 15.386 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C9.95433 20.0003 9.90867 20.0003 9.863 20C9.7725 20 9.682 19.997 9.592 19.992ZM3.583 16.31C3.50823 16.0953 3.48278 15.8665 3.50854 15.6406C3.5343 15.4147 3.6106 15.1975 3.73179 15.0051C3.85298 14.8128 4.01593 14.6502 4.20856 14.5294C4.40119 14.4086 4.61855 14.3328 4.8445 14.3075C8.7425 13.876 11.2815 13.915 15.1605 14.3165C15.3868 14.3401 15.6046 14.4149 15.7976 14.5354C15.9905 14.6559 16.1534 14.8189 16.2739 15.0119C16.3943 15.2048 16.4691 15.4227 16.4927 15.649C16.5162 15.8753 16.4877 16.1039 16.4095 16.3175C18.072 14.6354 19.003 12.365 19 10C19 5.0295 14.9705 1 10 1C5.0295 1 1 5.0295 1 10C1 12.458 1.9855 14.686 3.583 16.31Z"
        fill={color}
      />
    </svg>
  );
}

export function UserGroupIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  stroke = "#F79009",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
        stroke="#F79009"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
