import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Skeleton, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";

import LanguageProvider from "./Components/LanguageProvider";
import { theme } from "./theme";
import AppProvider from "providers/app";
import { legacy_createStore } from "redux";
import ReactGA from "react-ga4";
import { GA4_MEASUREMENT_ID } from "./constants/schemas";
import "./index.css";
import "./styles.scss";
import AppRouter from "./router";
import rootReducer from "redux/reducers";

const store = legacy_createStore(rootReducer);

const isProd = process.env.NODE_ENV === "production";
if (isProd) {
  ReactGA.initialize(GA4_MEASUREMENT_ID || "");
} else {
  ReactGA.initialize(GA4_MEASUREMENT_ID || "", {
    gaOptions: {
      debug_mode: true,
    },
    gtagOptions: {
      debug_mode: true,
    },
  });
}

// ReactGA.initialize(GA4_MEASUREMENT_ID || "");
export default function App() {
  return (
    <Provider store={store}>
      <LanguageProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <AppProvider>
              <Suspense fallback={<Skeleton variant="rounded" />}>
                <AppRouter />
              </Suspense>
            </AppProvider>
          </Router>
        </ThemeProvider>
      </LanguageProvider>
    </Provider>
  );
}
