import { Stack, styled } from "@mui/material";
import { Img } from "./Img";
import { formatImgUrl } from "utils/helpers";
import {
  Link,
  SmTextBold,
  SmTextMd,
  StyledRouterA,
  XsText,
} from "./Text";
import IntlMsg from "./IntlMsg";
import { formatCurrency } from "helpers/string";
import { memo } from "react";

type Props = {
  product: any;
};

const SuggestProduct = ({ product }: Props) => {
  let {
    price_min,
    price_max,
    moq,
    moq_unit,
    slug,
    farm_item_image,
    farm_item_name,
  } = product;

  return (
    <StyledItem direction="row" spacing={2}>
      <Link to={`/${slug}`}>
        <Img src={formatImgUrl(farm_item_image, 3)} className="item_image" />
      </Link>

      <Stack>
        <StyledRouterA to={`/${slug}`}>
          <SmTextMd className="item_name" component="h3">
            {farm_item_name}
          </SmTextMd>
        </StyledRouterA>

        <SmTextBold className="item_price">
          {price_min
            ? `$${formatCurrency(price_min)}${
                price_max && price_max > price_min
                  ? ` - $${formatCurrency(price_max)}`
                  : ""
              }`
            : ""}
        </SmTextBold>
        <XsText className="min_order_qlt">
          {moq && (
            <>
              <IntlMsg id="MinimumOrderQuantity" />: {moq} {moq_unit}
            </>
          )}
        </XsText>
      </Stack>
    </StyledItem>
  );
};

const StyledItem = styled(Stack)({
  ".item_image": {
    width: 84,
    height: 84,
    minWidth: 84,
    objectFit: "contain",
    borderRadius: 12,
  },
  ".item_name": {
    fontSize: 12,
    fontWeight: 500,
  },
  ".item_price": {
    fontSize: 12,
    fontWeight: 600,
    color: "var(--sub-color)",
  },
  ".min_order_qlt": {
    fontSize: 8,
  },
});

export default memo(SuggestProduct);
