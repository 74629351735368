import { PLAN_NAMES, SUPPLIER_PLANS } from "constants/data";
import { FILE_DOMAIN, RESIZE_IMG_DOMAIN } from "constants/schemas";
import dayjs from "dayjs";
import { OptionType } from "types";
import { UserPlanType } from "types/user";
import DEFAULT_IMAGE from "../Assets/images/default_img.webp";

export const removeSameOption = (options: OptionType[]) => [
  ...new Map(options.map((item: OptionType) => [item.value, item])).values(),
];

export function removeUndefinedFields<T extends Record<string, any>>(
  object: T
): Partial<T> {
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === "undefined") {
      delete object[key];
    }
  });
  return object;
}

export const followRel = (no_follow: number) =>
  no_follow === 1 ? "nofollow" : undefined;

export const getCurrentPlan = (plan?: UserPlanType) => {
  const now = dayjs();

  if (!plan) return null;

  return {
    ...(SUPPLIER_PLANS.find((p) => p.label === plan?.plan_name) ||
      SUPPLIER_PLANS.find((p) => p.label === PLAN_NAMES.free)),
    ...plan,
    isExpired: dayjs(plan.end_date).isBefore(now),
  };
};

export const formatImg = (
  suffix: string,
  type: number = -1,
  isThumbnail = false
) => {
  return suffix
    ? checkListProtocols(suffix)
      ? suffix
      : checkIsVideo(suffix)
      ? isThumbnail
        ? getThumbnail(suffix, type)
        : `${RESIZE_IMG_DOMAIN}${suffix}`
      : type > -1
      ? `${RESIZE_IMG_DOMAIN}/${getMatchImageSize(type)}${suffix}`
      : `${FILE_DOMAIN}${suffix}`
    : DEFAULT_IMAGE;
};

const checkListProtocols = (link = "") => {
  let protocols = ["http", "https"],
    checkListProtocols = false;
  protocols?.map((prototal) => {
    if (link?.indexOf(prototal) > -1) {
      checkListProtocols = true;
    }
    return prototal;
  });
  return checkListProtocols;
};

const getMatchImageSize = (type = 0) => {
  switch (type) {
    case 0:
      return "64x64";
    case 1:
      return "128x128";
    case 2:
      return "240x240";
    case 3:
      return "350x350";
    case 4:
      return "480x480";
    case 5:
      return "640x640";
    case 6:
      return "720x720";
    case 7:
      return "1024x1024";
    default:
      return "240x240";
  }
};

const getThumbnail = (url: string, type: number) => {
  let thumbnailImg = null;
  if (url) {
    thumbnailImg = url.replace("m3u8", "jpeg");
  }
  return `${RESIZE_IMG_DOMAIN}/${getMatchImageSize(type)}${thumbnailImg}`;
};

const checkIsVideo = (suffix: string) => {
  let isVideo = false;
  if (suffix) {
    isVideo = suffix?.indexOf(".m3u8") > -1;
  }
  return isVideo;
};
