import { SVGProps } from "react";

export function FlagIcon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M3.33301 12.5C3.33301 12.5 4.16634 11.6667 6.66634 11.6667C9.16634 11.6667 10.833 13.3334 13.333 13.3334C15.833 13.3334 16.6663 12.5 16.6663 12.5V2.50002C16.6663 2.50002 15.833 3.33335 13.333 3.33335C10.833 3.33335 9.16634 1.66669 6.66634 1.66669C4.16634 1.66669 3.33301 2.50002 3.33301 2.50002V12.5ZM3.33301 12.5V18.3334"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
