import { Client, Conversation } from "@twilio/conversations";
import { useLoading } from "providers/loading";
import { useNotify } from "providers/notify";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  onOpenChatContainer,
  onReloadHeaderChat as onReloadHeaderChatStore,
  setChatList,
  setTwlIdentify,
  setTwlToken,
} from "redux/actions/chat";
import { getChatClient, getTwlIdentity } from "redux/selectors/chat";
import { apiGetIdentity } from "services/api/chat";
import useUser from "./useUser";
import useAuth from "./useAuth";
import { isNotNull } from "helpers/string";
import { LOCALSTORAGE_KEYS, chatStorage } from "helpers/localStorage";
import { CreateConvsData } from "types/chat";

const useChat = () => {
  const chatClient: Client = useSelector(getChatClient);
  const twlIdentify = useSelector(getTwlIdentity);
  const { setLoading } = useLoading();
  const { setNotify } = useNotify();
  const dispatch = useDispatch();
  const { isLoggedIn } = useUser();
  const { onDirectLogin } = useAuth();

  const t = useIntl();

  const onOpenConversationWithIdentity = async (identity?: string) => {
    if (isLoggedIn) {
      const conversationName = `${twlIdentify}|${identity}`;
      const conversationExist = await checkConversationExist(conversationName);
      const tmpConversation = {
        uniqueName: conversationName,
      } as Conversation;
      if (conversationExist) {
        onOpenConversation(conversationExist.sid);
      } else {
        dispatch(onOpenChatContainer(tmpConversation));
      }
    } else {
      onDirectLogin();
    }
  };

  const checkConversationExist = async (
    value: string,
    type: keyof Conversation = "uniqueName"
  ): Promise<Conversation | undefined> => {
    try {
      let paginator = await chatClient.getSubscribedConversations();
      let conversations = paginator.items;
      while (paginator.hasNextPage) {
        paginator = await paginator.nextPage();
        conversations = conversations.concat(paginator.items);
      }
      const conversationExist = conversations.some(
        (conversation) => conversation[type] === value
      );
      return conversationExist
        ? conversations.find((conversation) => conversation[type] === value)
        : undefined;
    } catch (error) {
      console.error("Error checking conversation:", error);
      return undefined;
    }
  };

  const onCreateConverstation = async (
    data: CreateConvsData
  ): Promise<Conversation | undefined> => {
    const conversationName = `${twlIdentify}|${data.identity}`;
    try {
      if (chatClient) {
        setLoading(true);
        const { identity, attributes } = data;
        const conversation = await chatClient.createConversation({
          uniqueName: conversationName,
          attributes,
        });
        await conversation.join();
        const supplierIdentityData = await apiGetIdentity(identity);
        const supplierIdentity = supplierIdentityData.data?.message?.data;
        await conversation.add(supplierIdentity);
        onReloadHeaderChat();
        dispatch(onOpenChatContainer(conversation));
        setLoading(false);
        return conversation;
      }
    } catch (error: any) {
      console.log(error?.message);
      setLoading(false);
      setNotify({
        open: true,
        type: "error",
        msg: t.formatMessage({ id: "something_went_wrong" }),
      });
    }
  };

  const onDeleteConversation = useCallback(async (sid: string) => {
    if (chatClient) {
      try {
        const conversation = await chatClient.getConversationBySid(sid);
        await conversation.delete();
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const getConversationById = async (conversationId: string) => {
    try {
      const conversation = await chatClient.getConversationBySid(
        conversationId
      );
      return conversation;
    } catch (error) {
      console.error("Error fetching conversation:", error);
      throw error;
    }
  };

  const getUnreadMessagesCount = async () => {
    try {
      const paginator = await chatClient.getSubscribedConversations();
      const conversations = paginator.items;
      const unreadCounts: { [key: string]: number } = {};
      for (const conversation of conversations) {
        const unreadCount = await getUnreadCountForConversation(conversation);
        unreadCounts[conversation.sid] = unreadCount;
      }
      return unreadCounts;
    } catch (error) {
      console.error("Error fetching conversations:", error);
      throw error;
    }
  };

  const getUnreadCountForConversation = async (
    conversation: Conversation
  ): Promise<number> => {
    try {
      const messages = await conversation.getMessages();
      const messagesFromOther = messages.items.filter(
        (message) => message.author !== twlIdentify
      );
      const lastMessageIndex =
        messagesFromOther[messagesFromOther.length - 1]?.index;
      let messagesCount = 0;
      const participants = await conversation.getParticipants();
      const myParticipant = participants.find(
        (participant) => participant.identity === twlIdentify
      );
      const myLastReadMessageIndex = myParticipant?.lastReadMessageIndex;
      if (!isNotNull(myLastReadMessageIndex)) {
        messagesCount = messagesFromOther.length;
      } else {
        if (isNotNull(lastMessageIndex)) {
          messagesCount = lastMessageIndex - (myLastReadMessageIndex || 0);
        }
      }
      return messagesCount;
    } catch (error) {
      console.error(
        `Error getting unread count for conversation ${conversation.sid}:`,
        error
      );
      return 0;
    }
  };

  const onOpenConversation = async (
    conversationSid: string,
    chatListTmp?: Conversation[]
  ) => {
    try {
      const conversation = await chatClient.getConversationBySid(
        conversationSid
      );
      dispatch(onOpenChatContainer(conversation));
    } catch (error) {
      console.error("Error opening conversation:", error);
    }
  };

  const onClickSendMessage = async (message: string, identity: string) => {
    if (identity && message.trim() !== "") {
      try {
        const conversations = await chatClient.getSubscribedConversations();
        const conversation = conversations.items.find(async (conv) => {
          const participant = await conv.getParticipantByIdentity(identity);
          return participant ? true : false;
        });
        if (!conversation) {
          const newConversation: Conversation | undefined =
            await onCreateConverstation({
              identity,
            });
          if (newConversation) {
            await newConversation.sendMessage(message);
          }
        } else {
          await conversation.sendMessage(message);
        }
      } catch (error) {
        console.error("Failed to send message:", error);
      }
    }
  };

  const onReloadHeaderChat = () => {
    dispatch(onReloadHeaderChatStore());
  };

  const setStoreTwl = () => {
    dispatch(setTwlToken());
    dispatch(setTwlIdentify());
  };

  const setLocalStorerageTwl = () => {
    chatStorage.remove(LOCALSTORAGE_KEYS.twlk);
    chatStorage.remove(LOCALSTORAGE_KEYS.twlid);
  };

  const onClearChatKey = () => {
    dispatch(setChatList([]));
    setStoreTwl();
    setLocalStorerageTwl();
  };

  return {
    chatClient,
    twlIdentify,
    onCreateConverstation,
    onDeleteConversation,
    onOpenConversationWithIdentity,
    getConversationById,
    getUnreadMessagesCount,
    onOpenConversation,
    onClickSendMessage,
    getUnreadCountForConversation,
    onReloadHeaderChat,
    onClearChatKey,
  };
};
export default useChat;
