import { SVGProps } from "react";

export function PackageIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  stroke = "#F79009",
  strokeWidth = 2,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M16.5 9.40002L7.5 4.21002M3.27 6.96002L12 12.01L20.73 6.96002M12 22.08V12M21 16V8.00002C20.9996 7.6493 20.9071 7.30483 20.7315 7.00119C20.556 6.69754 20.3037 6.44539 20 6.27002L13 2.27002C12.696 2.09449 12.3511 2.00208 12 2.00208C11.6489 2.00208 11.304 2.09449 11 2.27002L4 6.27002C3.69626 6.44539 3.44398 6.69754 3.26846 7.00119C3.09294 7.30483 3.00036 7.6493 3 8.00002V16C3.00036 16.3508 3.09294 16.6952 3.26846 16.9989C3.44398 17.3025 3.69626 17.5547 4 17.73L11 21.73C11.304 21.9056 11.6489 21.998 12 21.998C12.3511 21.998 12.696 21.9056 13 21.73L20 17.73C20.3037 17.5547 20.556 17.3025 20.7315 16.9989C20.9071 16.6952 20.9996 16.3508 21 16Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LgPackageIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={40}
      height={41}
      viewBox={"0 0 40 41"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M27.5 16.4653L12.5 7.81529M5.45 12.3986L20 20.8153L34.55 12.3986M20 37.5986V20.7986M35 27.4653V14.132C34.9994 13.5474 34.8451 12.9733 34.5526 12.4672C34.26 11.9611 33.8396 11.5409 33.3333 11.2486L21.6667 4.58196C21.1599 4.2894 20.5851 4.13538 20 4.13538C19.4149 4.13538 18.8401 4.2894 18.3333 4.58196L6.66667 11.2486C6.16044 11.5409 5.73997 11.9611 5.44744 12.4672C5.1549 12.9733 5.0006 13.5474 5 14.132V27.4653C5.0006 28.0498 5.1549 28.6239 5.44744 29.13C5.73997 29.6361 6.16044 30.0564 6.66667 30.3486L18.3333 37.0153C18.8401 37.3079 19.4149 37.4619 20 37.4619C20.5851 37.4619 21.1599 37.3079 21.6667 37.0153L33.3333 30.3486C33.8396 30.0564 34.26 29.6361 34.5526 29.13C34.8451 28.6239 34.9994 28.0498 35 27.4653Z"
        stroke="#008934"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SmPackageIcon({
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={16}
      viewBox={"0 0 15 16"}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M10.3125 6.37506L4.6875 3.13131M2.04375 4.85006L7.5 8.00631L12.9562 4.85006M7.5 14.3001V8.00006M13.125 10.5001V5.50006C13.1248 5.28086 13.0669 5.06557 12.9572 4.87579C12.8475 4.68601 12.6898 4.52841 12.5 4.41881L8.125 1.91881C7.93498 1.8091 7.71942 1.75134 7.5 1.75134C7.28058 1.75134 7.06502 1.8091 6.875 1.91881L2.5 4.41881C2.31016 4.52841 2.15249 4.68601 2.04279 4.87579C1.93309 5.06557 1.87522 5.28086 1.875 5.50006V10.5001C1.87522 10.7193 1.93309 10.9346 2.04279 11.1243C2.15249 11.3141 2.31016 11.4717 2.5 11.5813L6.875 14.0813C7.06502 14.191 7.28058 14.2488 7.5 14.2488C7.71942 14.2488 7.93498 14.191 8.125 14.0813L12.5 11.5813C12.6898 11.4717 12.8475 11.3141 12.9572 11.1243C13.0669 10.9346 13.1248 10.7193 13.125 10.5001Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
