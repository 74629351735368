import { Button, styled } from "@mui/material";

const ButtonBase = styled(Button)(({ theme }) => ({
  textTransform: "initial",
  borderRadius: 8,
  fontWeight: "500",
  padding: "6px 16px",
  fontSize: 14,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    ".MuiButton-icon svg": {
      maxHeight: 21,
    },
  },
}));

export default ButtonBase;
