import { SVGProps } from "react";

export function CrownIcon({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  stroke = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_13004_76948)">
        <path
          d="M0.833252 13.332H19.1666"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M19.1666 4.16667L14.9999 7.5L9.99992 2.5L4.99992 7.5L0.833252 4.16667V15C0.833252 15.442 1.00885 15.866 1.32141 16.1785C1.63397 16.4911 2.05789 16.6667 2.49992 16.6667H17.4999C17.9419 16.6667 18.3659 16.4911 18.6784 16.1785C18.991 15.866 19.1666 15.442 19.1666 15V4.16667Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_13004_76948">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SmCrownIcon({
  stroke = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 16"
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <g clipPath="url(#clip0_14877_172612)">
        <path
          d="M0.625 10.4999H14.375"
          stroke="#008934"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M14.375 3.625L11.25 6.125L7.5 2.375L3.75 6.125L0.625 3.625V11.75C0.625 12.0815 0.756696 12.3995 0.991117 12.6339C1.22554 12.8683 1.54348 13 1.875 13H13.125C13.4565 13 13.7745 12.8683 14.0089 12.6339C14.2433 12.3995 14.375 12.0815 14.375 11.75V3.625Z"
          stroke="#008934"
          stroke-width="1.5"
          stroke-miterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_14877_172612">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
